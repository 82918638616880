<template>
  <main class="content full-height col-centered">
    <h1>Email Verified</h1>
    <p>Thank you for verifying your email.</p>
    <p>Logging in....</p>
  </main>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { defineComponent } from 'vue'
import { AuthHelperService, useServiceInjector } from '@/injection'

export default defineComponent({
  setup() {
    const router = useRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    try {
      const session = auth.getSessionFromUrl()
      const minDelay = new Promise((r) => setTimeout(r, 2000))
      Promise.all([session, minDelay]).then(() => {
        router.push('/')
      })
    }
    catch (e) {
      console.error(e);
    }
  },
})
</script>