
import { useRouter } from 'vue-router'
import { defineComponent } from 'vue'
import { AuthHelperService, useServiceInjector } from '@/injection'

export default defineComponent({
  setup() {
    const router = useRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    try {
      const session = auth.getSessionFromUrl()
      const minDelay = new Promise((r) => setTimeout(r, 2000))
      Promise.all([session, minDelay]).then(() => {
        router.push('/')
      })
    }
    catch (e) {
      console.error(e);
    }
  },
})
